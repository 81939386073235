/**
 * @generated SignedSource<<85b81d9806d56a00c740175ce300a3e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FormInput = {
  date: string;
  endTime: number;
  field: string;
  shop: string;
  startTime: number;
};
export type AppMutation$variables = {
  attempts: number;
  connections: ReadonlyArray<string>;
  form: FormInput;
  interval: number;
  time: any;
};
export type AppMutation$data = {
  readonly createJob: {
    readonly jobEdge: {
      readonly node: {
        readonly id: string;
        readonly status: {
          readonly __typename: "Completed";
          readonly log: string | null;
          readonly success: boolean;
        } | {
          readonly __typename: "Scheduled";
          readonly log: string | null;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
      } | null;
    };
  };
};
export type AppMutation = {
  response: AppMutation$data;
  variables: AppMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attempts"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "form"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "time"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "attempts",
        "variableName": "attempts"
      },
      {
        "kind": "Variable",
        "name": "form",
        "variableName": "form"
      },
      {
        "kind": "Variable",
        "name": "interval",
        "variableName": "interval"
      },
      {
        "kind": "Variable",
        "name": "time",
        "variableName": "time"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "log",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "JobEdge",
  "kind": "LinkedField",
  "name": "jobEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Job",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/)
              ],
              "type": "Scheduled",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "success",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "type": "Completed",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CreateJobResponse",
        "kind": "LinkedField",
        "name": "createJob",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CreateJobResponse",
        "kind": "LinkedField",
        "name": "createJob",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "jobEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fd6e3f19db52e283d5b360608896f25",
    "id": null,
    "metadata": {},
    "name": "AppMutation",
    "operationKind": "mutation",
    "text": "mutation AppMutation(\n  $time: JsDate!\n  $attempts: Int!\n  $interval: Int!\n  $form: FormInput!\n) {\n  createJob(input: {time: $time, attempts: $attempts, interval: $interval, form: $form}) {\n    jobEdge {\n      node {\n        id\n        status {\n          __typename\n          ... on Scheduled {\n            log\n          }\n          ... on Completed {\n            success\n            log\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "903faff9c33d1e100337c6b71a871733";

export default node;
